import React from "react";
import { addTrailingSlashToUrl } from "src/utils";
import { NotFound404 } from "../components/SemanticTheme/NotFound404";
import { pickValuesAsString } from "../utils/common";
import Image from "next/legacy/image";
import {
  Page404,
  Page404EntityResponse,
} from "src/graphql/generated-strapi/types";
import { updateAbsoluteLink } from "@components/Utils";

type CmsData = ReturnType<typeof makeCmsData>;
const makeCmsData = (content: Page404) => ({
  ...pickValuesAsString(content, [
    "LogoAltText",
    "Title",
    "SubTitle",
    "ButtonText",
  ]),
  logo: content?.["Logo"]?.data?.attributes?.url,
  buttonRef: updateAbsoluteLink(
    content?.["ButtonRef"]?.data?.attributes?.PageRoute,
  ),
});

interface NotFoundPageProps {
  rest: {
    strapiContent: {
      data: { page404: Page404EntityResponse };
    };
    experiments?: { [key in string]: string | boolean };
  };
}

const NotFoundPageComponent: React.FC<NotFoundPageProps> = (
  props,
): JSX.Element => {
  const NotFoundPageCmsData =
    props?.rest?.strapiContent?.data?.page404?.data?.attributes;
  const cmsData = makeCmsData(NotFoundPageCmsData);

  const ctaURL = cmsData?.buttonRef === "/" ? cmsData?.buttonRef : "/";

  return (
    <NotFound404
      Logo={() => (
        <Image
          priority
          src={cmsData.logo}
          alt={cmsData.LogoAltText}
          width={150}
          height={56.34}
        />
      )}
      title={cmsData.Title}
      subtitle={cmsData.SubTitle}
      buttonText={cmsData.ButtonText}
      buttonUrl={ctaURL}
    />
  );
};

export default NotFoundPageComponent;
